import React from "react";
import SansaLogo from "./image/sansa-logo.png";
import { Bars3Icon } from "@heroicons/react/24/solid";

const App = () => {
  return(
    <>
      <div className="container px-4 py-2 fixed shadow-xl bg-blue-800 w-full flex items-center justify-center">
        <div className="mr-auto flex items-center justify-center">
          <img src={SansaLogo} className="mr-2 bg-white rounded-full h-16 w-16 border-2 border-solid border-slate-400" />
          <p className="w-40 text-md font-bold text-white">PT. SANSA MULYA SEMPURNA</p>
        </div>
        <Bars3Icon className="ml-auto w-10 h-10 text-white" />
      </div>
      <h1 className="text-3xl font-bold">SANSA EXPRESS</h1>
      <p>Sansa Express didirikan pada Hari Jum’at Tanggal 05 Bulan 08 Tahun 2022, denga notaris Victory, Sarjana Hukum. Yang di sahkan melalui SK. Mentri Kehakiman dan Hak Asasi Manusia RI No. AHU-0055992.AH.01.01.Tahun 2016, Tanggal 15 Desember 2002.dan resmi di sahkan sebagai (PT) Perseroan Terbatas dengan nama PT. Sansa Mulya Sempurna.</p>
      <p>Sansa Express adalah perusahaan Expedisi dengan pelayanan dan usaha yang maksimal selalu memberikan servis yang terbaik, dan terus ber- inovasi,dengan tujuan memberikan pelayanan terbaik untuk kepuasan customer kami</p>
      <br/><br/>
      <h1 className="text-3xl font-bold">Perfect Delivery Worldwide</h1>
      <h2 className="text-2xl font-bold">VALUE</h2>
      <h3 className="text-xl font-bold">Anything, Anytime, Anywhere.</h3>
      <p>Sansa Express siap melakukan pick up dan mengirim seluruh item produk pelanggan di manapun, baik reguler atau express melalui pengiriman darat, laut dan udara. Dengan sumber daya manusia serta armada yang optimal yang siap bekerja 24 jam dan menjaga kepedulian terhadap keamanan cargo yang ketat di mulai dari B.O.D (Board of Director) level sampai fungsional level yang siap mendistribusikan ke seluruh Indonesia.</p>
      <h2 className="text-2xl font-bold">MOTO</h2>
      <p>Sebagi Moto perseroan yang telah disepakati oleh seluruh karyawan dengan tujuan memberikan pelayanan yang lebih baik kepada pelanggan, sesuai dengan konsep PDCA (Plan Do Check Action)</p>
      <br/><br/>
      <h2 className="text-2xl font-bold">ADVANTAGE</h2>
      <p>Seluruh staf Sansa Express telah berpengalaman dalam mengelola dan mengirim barang dengan mengedepankan pelayanan total support yang sangat customize, flexible dan humble.</p>
      <h2 className="text-2xl font-bold">FACILITY</h2>
      <p>Untuk mendukung operasional dan pendistribusian barang customer, kami memiliki fasilitas :</p>
      <ol>
        <li>Grand Max Blindvan</li>
        <li>Mitsubishi L 300</li>
        <li>Mitsubishi Engkel Box</li>
        <li>Gudang + 200 m2</li>
        <li>SDM yang berpengalaman</li>
      </ol>
      <br/><br/>
      <h1 className="text-3xl font-bold">OUR SERVICES</h1>
      <h2 className="text-2xl font-bold">Air Cargo</h2>
      <p>Melayani pengiriman Ekspress melalui pesawat udara.</p>
      <h2 className="text-2xl font-bold">Sea Cargo</h2>
      <p>Melayani pengiriman Ekspress dan Reguler melalui kapal cepat.</p>
      <h2 className="text-2xl font-bold">Land Cargo</h2>
      <p>Melayani pengiriman Ekspress dan Reguler melalui darat.</p>
      <br/><br/>
      <h1 className="text-3xl font-bold">Kecepatan</h1>
      <p>Kecepatan pelayanan adalah salah satu target utama kepada customer kami.</p>
      <h1 className="text-3xl font-bold">Ketepatan</h1>
      <p>Akurasi pelayanan cargo untuk setiap pengiriman.</p>
      <h1 className="text-3xl font-bold">Best Price</h1>
      <p>Harga yang sangat terjangkau untuk layanan jasa yang kami berikan</p>
      <h1 className="text-3xl font-bold">Layanan 24 Jam</h1>
      <p>Layanan Customer Services 24 jam</p>
      <br/><br/>
      <h1 className="text-3xl font-bold">Our Customer</h1>
      <ol>
        <li>PT. Buana Rajasa Tani</li>
        <li>PT. Sukses Bersama Sejahtera</li>
        <li>UD. Lintang Mulia Jaya</li>
        <li>CV. Surya Mitraniaga Utama</li>
        <li>CV. Karya Mandiri</li>
        <li>UD. Fanza Makmur</li>
      </ol>
      <br/><br/>
      <h1 className="text-3xl font-bold">Contact Information</h1>
      <ol>
        <li>Telephone : 0813 3555 5641, 0899 8480 308</li>
        <li>www.sansamulyasempurna.com</li>
        <li>info@sansamulyasempurna.com</li>
      </ol>
      <br/><br/>
      <p>Created with Love by AAKEN Developer</p>
    </>
  );
}

export default App;
